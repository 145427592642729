import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { AuthenticationService } from '../_services/authentication.service';
import { LoaderService } from '../_services/loader.service';
import * as forge from 'node-forge';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../../environments/environment';
import { catchError, from, map, NEVER, of, switchMap, tap } from 'rxjs';
import { AlertMessage } from './alert.message';

@Injectable()
export class EncdeInterceptor implements HttpInterceptor {
  urlsToSkipRequest: Array<string>;
  urlsToSkipResponseEncrypt: Array<string>;

  publicKey: string = `-----BEGIN PUBLIC KEY-----
  MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAskgPKBcNpz71mi4NSYa5
  mazJrO0WZim7T2yy7qPxk2NqQE7OmWWakLJcaeUYnI0kO3yC57vck66RPCjKxWuW
  SGZ7dHXe0bWb5IXjcT4mNdnUIalR+lV8czsoH/wDUvkQdG1SJ+IxzW64WvoaCRZ+
  /4wBF2cSUh9oLwGEXiodUJ9oJXFZVPKGCEjPcBI0vC2ADBRmVQ1sKsZg8zbHN+gu
  U9rPLFzN4YNrCnEsSezVw/W1FKVS8J/Xx4HSSg7AyVwniz8eHi0e3a8VzFg+H09I
  5wK+w39sjDYfAdnJUkr6PjtSbN4/Sg/NMkKB2Ngn8oj7LCfe/7RNqIdiS+dQuSFg
  eQIDAQAB
  -----END PUBLIC KEY-----`;

  keySize = 256;
  ivSize = 128;
  saltSize = 256;
  iterations = 1000;

  randomKeyValue: string = this.randomKey(16);

  constructor(private alertMessage: AlertMessage, private authenticationService: AuthenticationService, public loaderService: LoaderService) {
    this.urlsToSkipRequest = [

      'NewsArticles/UploadFile',
      'Company/SaveCompany',
      'Company/UpdateCompany',
      'Login/saveverifychange',
      'CompanyMY/SaveCompany',
      'CompanyMY/UpdateCompany',
    ];

    this.urlsToSkipResponseEncrypt = [
      'Login/AuthenticateUser',
      'Company/SaveCompany',
      'Company/UpdateCompany',
      'Company/EditCompany',
      'Login/saveverifychange',
      'CompanyMY/SaveCompany',
      'CompanyMY/UpdateCompany',
    ];
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isValidRequestForInterceptor(req.url)) {
      var encryptedBody = "";
      let contentType: string = "";
      if (req.body != null) {
        encryptedBody = JSON.stringify(req.body);

        if ((req.body instanceof HttpParams))
          contentType = 'application/x-www-form-urlencoded;charset=UTF-8';
        else
          contentType = 'application/json';
      }
      //&& !req.url.toLowerCase().includes("_digismecrm") && !req.url.toLowerCase().includes("stgcrmreplica")
      if (environment.isEncDecAllowed && !req.url.toLowerCase().includes("attachment") && !req.url.toLowerCase().includes("documentupload") && !req.url.toLowerCase().includes("crmapi") && !req.url.toLowerCase().includes("crm")) {
        var rsa = forge.pki.publicKeyFromPem(this.publicKey);
        var rsaKey = window.btoa(rsa.encrypt(this.randomKeyValue));

        if (req.method.toLowerCase() == "get") {
          let query: string = "";
          let splitURL = req.url.split('?');

          if (typeof (splitURL[1]) != 'undefined') {
            query = this.encrypt(splitURL[1], this.randomKeyValue);
          }

          if (query != "") {
            query = splitURL[0] + "?" + query;
          }
          else {
            query = splitURL[0];
          }

          req = req.clone({
            url: query,
            setHeaders: {
              'Custom-Key': rsaKey,
              'Custom-Allowed': 'true',
              'Custom-ResponseAllowed': environment.isResponseEncAllowed.toString()
            }
          });
        }

        if (req.body != null) {
          req = req.clone({
            body: JSON.parse('{"str":"' + this.encrypt(encryptedBody, this.randomKeyValue) + '"}'),
            setHeaders: {
              'Custom-Key': rsaKey,
              'Content-Type': contentType,
              'Custom-Allowed': 'true',
              'Custom-ResponseAllowed': this.isValidRequestForResponseEncrypt(req.url) ? environment.isResponseEncAllowed.toString() : "false"
            }
          });
        }

        if (req.method.toLowerCase() == "post" && req.body == null) {
          req = req.clone({
            setHeaders: {
              'Custom-Key': rsaKey,
              'Content-Type': contentType,
              'Custom-Allowed': 'true',
              'Custom-ResponseAllowed': this.isValidRequestForResponseEncrypt(req.url) ? environment.isResponseEncAllowed.toString() : "false"
            }
          });
        }

        if (environment.isResponseEncAllowed && this.isValidRequestForResponseEncrypt(req.url)) {
          return next.handle(req).pipe(
            map((resp: any) => {
              if (resp instanceof HttpResponse) {
                var json = this.decrypt((resp.body as responseEncrypt).str, this.randomKeyValue);
                resp = resp.clone<any>({ body: JSON.parse(json) });
                return resp;
              }
            })
          );
        } else {
          return next.handle(req);
        }
      }
      else {
        return next.handle(req);
      }
    }
    else {
      return next.handle(req);
    }
  }

  randomKey(length: number) {
    var result = '';
    var characters = '@abcdefghijklmnopqrstuvwxyz123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  encrypt(msg: any, pass: any) {
    var salt = CryptoJS.lib.WordArray.random(this.saltSize / 8);

    var key = CryptoJS.PBKDF2(pass, salt, {
      keySize: this.keySize / 32,
      iterations: this.iterations
    });

    var iv = CryptoJS.lib.WordArray.random(this.ivSize / 8);

    var encrypted = CryptoJS.AES.encrypt(msg, key, {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC

    });

    var encryptedHex = this.base64ToHex(encrypted.toString());

    var base64result = this.hexToBase64([salt, iv, encryptedHex].join(''));

    return base64result;
  }

  decrypt(transitmessage: any, pass: any) {
    if (transitmessage != null && transitmessage != undefined && transitmessage != '') {
      var hexResult = this.base64ToHex(transitmessage)

      var salt = CryptoJS.enc.Hex.parse(hexResult.substr(0, 64));
      var iv = CryptoJS.enc.Hex.parse(hexResult.substr(64, 32));
      var encrypted = this.hexToBase64(hexResult.substring(96));

      var key = CryptoJS.PBKDF2(pass, salt, {
        keySize: this.keySize / 32,
        iterations: this.iterations
      });

      var decrypted = CryptoJS.AES.decrypt(encrypted, key, {
        iv: iv,
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC

      })

      return decrypted.toString(CryptoJS.enc.Utf8);
    }
    else
      return '{"str":""}';
  }

  hexToBase64(str: any) {
    let array = str.replace(/\r|\n/g, "").replace(/([\da-fA-F]{2}) ?/g, "0x$1 ").replace(/ +$/, "").split(" ");
    return btoa(String.fromCharCode.apply(null,
      array)
    );
  }

  base64ToHex(str: any) {
    for (var i = 0, bin = atob(str), hex = []; i < bin.length; ++i) {
      var tmp = bin.charCodeAt(i).toString(16);
      if (tmp.length === 1) tmp = "0" + tmp;
      hex[hex.length] = tmp;
    }
    return hex.join("");
  }

  private isValidRequestForInterceptor(requestUrl: string): boolean {
    let positionIndicator: string = 'api/';
    let position = requestUrl.indexOf(positionIndicator);
    if (position > 0) {
      let destination: string = requestUrl.substr(position + positionIndicator.length);
      for (let address of this.urlsToSkipRequest) {
        if (new RegExp(address.toLowerCase()).test(destination.toLowerCase())) {
          //console.log("address ", address.toLowerCase());
          return false;
        }
      }
    }
    return true;
  }

  private isValidRequestForResponseEncrypt(requestUrl: string): boolean {
    let positionIndicator: string = 'api/';
    let position = requestUrl.indexOf(positionIndicator);
    if (position > 0) {
      let destination: string = requestUrl.substr(position + positionIndicator.length);
      for (let address of this.urlsToSkipResponseEncrypt) {
        if (new RegExp(address.toLowerCase()).test(destination.toLowerCase())) {
          //console.log("address ", address.toLowerCase());
          return false;
        }
      }
    }
    return true;
  }

  private isUniqueCodeCharExists(inputText: string) {
    let isExists = false;
    for (let i = 0; i < inputText.length; i++) {
      let ASCIICode = inputText.charCodeAt(i);
      if (ASCIICode === 65308 || ASCIICode === 65310) {
        isExists = true;
      }
    }
    return isExists;
  }
}

export class responseEncrypt {
  str?: string = '';
}
