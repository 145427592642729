export interface LoginRequest {
  UserName: string;
  Password: string;
}

export interface Login {
  UserData: LoginResponse;
  Token: string;
  RefreshToken: string;
}

export interface LoginResponse {
  userID: number;
  CustomerID: number;
  CustomerName: string;
  ShiftorSch: string;
  StaffImage: string;
  Country: string;
  _UserMenuRights: UserMenuRights[];
  _usercompany: UserCompany[];
  _usermenu: UserMenu[];
  CompanyID: number;
  CompanyName: string;
  CompanyCode: string;
  CompanyLogo: string;
  CustomerCode: string;
  LoginUserType: number;
  EmpCode: string;
  EmpName: string;
  EmailID: string;
  LicenseDate: string;
  HeadCount: number;
  countrycode: number;
  SkipWizard?: boolean;
  TrialStatus: string;
  CurrentHeadCount?: number;
  CompanyHeadCount?: number;

}

export interface UserCompany {
  Company_Id: number;
  CompanyName: string;
  CompanyLogo: string;
  CompanyUserType: string;
  CompanyCode: string;
}

export interface UserMenu {
  MenuID: number;
  ParentMenuID: number;
  SubMenuID: number;
  MenuName: string;
  PageUrl: string;
  Position: number;
  MenuType: string;
  CanEdit: boolean;
  childMenu: UserMenu[] | null;
}
export interface UserMenuRights {
  MenuName: string;
  Access:string
}

export enum Country {
  Singapore = '1',
  India = '5',
  NewzLand = '6',
  Malaysia = '2',
}
